import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, ImageContainer } from './styles'

export const DetailImage = ({ image }) => (
  <Container>
    <ImageContainer>
      <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
    </ImageContainer>
  </Container>
)

DetailImage.propTypes = {
  image: PropTypes.shape({
    title: PropTypes.string.isRequired,
    gatsbyImageData: PropTypes.object.isRequired,
  }),
}

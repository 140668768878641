import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { BuyNowLinkCSS } from 'src/utils/styles/global-styles'
import { BUY_LINK, CAL_BUY_LINK, CAL_MAX_BUY_LINK, PLUS_BUY_LINK } from 'src/utils/constants'

const Container = styled.div`
  background-color: ${(props) => props.theme.cream};
  padding: 30px;
  display: flex;
  justify-content: center;
`

export const BuyNowLink = styled(Link)`
  ${BuyNowLinkCSS}
`

const CTAS = {
  Calendar: {
    path: CAL_BUY_LINK,
    label: 'Shop Calendars',
  },
  Frame: {
    path: BUY_LINK,
    label: 'Shop Frames',
  },
  FramePlus: {
    path: PLUS_BUY_LINK,
    label: 'Shop Plus',
  },
  CalMax: {
    path: CAL_MAX_BUY_LINK,
    label: 'Shop Calendars',
  },
}

export const DynamicCta = ({ ctaLink }) => {
  const cta = CTAS[ctaLink]
  return (
    <Container>
      <BuyNowLink to={cta.path}>{cta.label}</BuyNowLink>
    </Container>
  )
}

DynamicCta.propTypes = {
  ctaLink: PropTypes.oneOf(Object.keys(CTAS)),
}

import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  background-color: ${(props) => props.theme.lime};
  padding: 20px;
`

export const RowOrCol = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
  }
`

export const Col = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

export const Header = styled.h2`
  font-family: 'FilsonProBold';
  text-align: center;
  margin-bottom: 40px;
`

export const Title = styled.h3`
  font-family: 'FilsonProBold';
`

export const Description = styled.p`
  font-family: 'FilsonPro';
`

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const ImageContainer = styled.div`
  width: 100px;
`

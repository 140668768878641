import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RichText } from '../../atoms/RichText'
import { RowOrCol, ImageContainer, TextContainer, Header } from './styles'

export const Hero = ({ title, image, descriptionRichText }) => (
  <RowOrCol>
    <ImageContainer>
      <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
    </ImageContainer>
    <TextContainer>
      <Header>{title}</Header>
      <RichText content={descriptionRichText} />
    </TextContainer>
  </RowOrCol>
)

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    title: PropTypes.string.isRequired,
    gatsbyImageData: PropTypes.object.isRequired,
  }),
  descriptionRichText: PropTypes.shape({
    raw: PropTypes.string.isRequired,
  }),
}

import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  background-color: ${(props) => props.theme.cream};
  display: flex;
  padding: 20px;
  justify-content: center;
  text-align: center;
`

export const TextContainer = styled.div`
  width: 600px;
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`

export const Header = styled.h2`
  font-family: 'FilsonProBold';
`

export const CustomerName = styled.p`
  font-family: 'FilsonPro';
  color: ${(props) => props.theme.grayLight};
`

import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  background-color: ${(props) => props.theme.blueLight};
  display: flex;
  justify-content: center;
`

export const ImageContainer = styled.div`
  width: 600px;
  padding: 40px 0;
  @media (max-width: ${breakpoints.l}px) {
    width: 90%;
    padding: 0;
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '../../atoms/RichText'
import { Container, TextContainer, Header, CustomerName } from './styles'

export const Testimonial = ({ header, bodyRichText, customerName }) => (
  <Container>
    <TextContainer>
      {header && <Header>{header}</Header>}
      <RichText content={bodyRichText} />
      {customerName && <CustomerName>{customerName}</CustomerName>}
    </TextContainer>
  </Container>
)

Testimonial.propTypes = {
  header: PropTypes.string,
  bodyRichText: PropTypes.shape({
    raw: PropTypes.string.isRequired,
  }),
  customerName: PropTypes.string,
}

import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Header = styled.h1`
  font-family: 'FilsonProBold';
`

export const RowOrCol = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.cream};
  flex-direction: row;
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
  }
`

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${(props) => props.theme.cream};
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const ImageContainer = styled.div`
  flex: 1;
`

import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styles'

export const YoutubeVideo = ({ youtubeId }) => (
  <Container>
    <iframe
      width="560"
      height="315"
      // Autoplay doesn't work in most browsers unless the video is muted,
      // and rel=0 means don't show random recommended videos!
      src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&mute=1&rel=0`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowfullscreen
    />
  </Container>
)

YoutubeVideo.propTypes = {
  youtubeId: PropTypes.string.isRequired,
}

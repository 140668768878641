import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '../../atoms/RichText'
import { Container, TextContainer } from './styles'

export const WhyWeBuiltIt = ({ bodyRichText }) => (
  <Container>
    <TextContainer>
      <RichText content={bodyRichText} />
    </TextContainer>
  </Container>
)

WhyWeBuiltIt.propTypes = {
  bodyRichText: PropTypes.shape({
    raw: PropTypes.string.isRequired,
  }),
}

import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.forest};
  padding: 20px;
  justify-content: center;
`

export const TextContainer = styled.div`
  color: ${(props) => props.theme.cream};
  width: 600px;
  text-align: center;
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`
